import React from 'react'
import Layout from "../../components/Layout"

class CaseStudyFacultyDisclosuresPage extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <div className="aga-container aga-fade-in">
            <h1>Faculty</h1>
            <p>The American Gastroenterology Association brought together this team of leading primary care physicians and gastroenterologists to develop an education resource for health care providers to foster collaboration between primary care practitioners and gastroenterologists to provide optimal, evidence-based care for patients with IBD.</p>
            
            <h2>Doron Schneider, MD</h2>
            <h3 className="aga-subtitle-faculty">Internal Medicine</h3>
            <p>Dr. Doron Schneider is the Chief Patient Safety and Quality Officer for Abington Health and serves as the Deputy Program Director of the Internal Medicine Residency Program at Abington Memorial Hospital.</p>
            <p>Dr. Schneider served as the task force chair for the National Institute of Diabetes and Digestive and Kidney Diseases Practice Transformation education program for physicians and health care teams. Dr. Schneider is also a reviewer for ACP Diabetes Self-Assessment Program, ACP Hospitalist and <em>The Journal of Rheumatology</em>.</p>
            <p>Dr. Schneider received his medical degree from New Jersey Medical School, Newark, and completed his internal medicine residency at New England Deaconess Hospital in Boston and his internal medicine fellowship at Harvard Medical School.</p>

            <h2>Sunanda V. Kane, MD</h2>
            <h3 className="aga-subtitle-faculty">Gastroenterology</h3>
            <p>Dr. Sunanda V. Kane is a Professor of Medicine at the Mayo Clinic. Dr. Kane has co-edited a pocket guide to the management of IBD for practitioners and written a book for patients on self-management. Dr. Kane is Past Chair of the National Patient Education Committee for the Crohn’s and Colitis Foundation of America.</p>
            <p>Dr. Kane is currently the President-Elect of the American College of Gastroenterology and sits on the GI Specialty Council of the American Board of Internal Medicine. Within the Division of Gastroenterology and Hepatology, she is the Chair of Quality, where she is responsible for oversight of all quality improvement projects, measuring quality metrics for the faculty, leading annual credentialing reviews, and is an adjunct faculty member to the Mayo Quality Academy.</p>
            <p>Dr. Kane received her medical degree from Rush Medical College, Chicago, and completed her internal medicine residency at Rush Presbyterian St. Luke's Medical Center in Chicago, and gastroenterology fellowship at the University of Chicago.</p>

            <h2>Gary R. Lichtenstein, MD</h2>
            <h3 className="aga-subtitle-faculty">Gastroenterology</h3>
            <p>Dr. Gary R. Lichtenstein is Professor of Medicine and Director of the Inflammatory Bowel Disease Center at the Hospital of the University of Pennsylvania in Philadelphia.</p>
            <p>Dr. Lichtenstein was lead author of the <a href="https://gi.org/guideline/management-of-crohns-disease-in-adults/" target="_blank" rel="noopener noreferrer">ACG Clinical Guideline: Management of Crohn’s Disease in Adults</a> and author on more than 325 peer-reviewed articles that have been published in the <em>American Journal of Gastroenterology, Inflammatory Bowel Diseases, and Alimentary Pharmacology and Therapeutics</em>.</p>
            <p>Dr. Lichtenstein received his medical degree from Mount Sinai School of Medicine, New York City, and completed his internal medicine residency at Duke University Medical Center in Durham, NC, and gastroenterology fellowship at the Hospital of the University of Pennsylvania.</p>

            <h2>Stephen A. Brunton, MD</h2>
            <h3 className="aga-subtitle-faculty">Family Medicine</h3>
            <p>Dr. Stephen A. Brunton is the clinical professor in the Department of Family Medicine at the University of North Carolina, Chapel Hill and executive vice president for education for the Primary Care Education Consortium.</p>
            <p>Dr. Brunton serves in editorial capacities for <em>Internal Medicine Alert, Clinical Diabetes, Consultant, Journal of Gastroenterology and Hepatology and the Journal of Clinical Hypertension. He also serves as a reviewer for American Family Physician, the Southern Medical Journal, Expert Review of Endocrinology and Metabolism and the Journal of Family Practice</em>. Dr. Brunton is a past president of the California Academy of Family Physicians and the Association of Family Medicine Residency Directors.</p>
            <p>Dr. Brunton received his medical degree form Monash University Medical School in Melbourne, Australia, and completed his family practice residency at Long Beach Memorial Medical Center in California.</p>
          </div>
        </Layout>
      </div>
    )
  }
}

export default CaseStudyFacultyDisclosuresPage
